import React from "react";
import { Link } from "gatsby";
import { IoMdArrowBack } from "react-icons/io";
import "./BackButtonHeader.scss";
function BackButtonHeader({ title }: { title: string }): JSX.Element {
  return (
    <div className="header">
      <Link to="/">
        <IoMdArrowBack size="2em" />
      </Link>
      <h1>{title}</h1>
    </div>
  );
}

export default BackButtonHeader;
