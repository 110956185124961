import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";
import BackButtonHeader from "../../components/BackButtonHeader";
import "./projects.scss";

function LOCFamiliarity(): JSX.Element {
  return (
    <div className="project-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Eshed Margalit | Object Familiarity in LOC</title>
        <html lang="en" />
      </Helmet>
      <BackButtonHeader title="Object Familiarity in LOC" />

      <div className="project-info">
        <p>
          Hundreds of studies have explored the Lateral Occipital Complex (LOC),
          which is critical for shape perception. In a pair of papers, we
          measured LOC responses to four categories of stimuli: familiar
          objects, novel objects constructed by rearranging the parts of the
          familiar objects, scattered objects in which the parts were no longer
          touching, and a pixel-scrambled control condition. Our findings
          suggest that LOC is sensitive to inter-part relations, but not
          necessarily to object familiarity.
        </p>
        <StaticImage
          className="project-image"
          src="../../images/projectImages/loc_familiarity.png"
          alt="3D representation of a brain with red splotches indicating activity"
          placeholder="tracedSVG"
          height={250}
          width={250}
        />
      </div>

      <hr />

      <h3>Read the Papers</h3>
      <a
        href="https://jov.arvojournals.org/article.aspx?articleid=2551659"
        target="_blank"
        rel="noreferrer"
      >
        <div className="project-link">
          <div className="title">
            Margalit, E., Shah, M. P., Tjan, B. S., Biederman, I., Keller, B., &
            Brenner, R. (2016). The lateral occipital complex shows no net
            response to object familiarity. Journal of vision, 16(11), 3-3.
          </div>
        </div>
      </a>

      <a
        href="https://direct.mit.edu/jocn/article/29/9/1595/28683/What-Is-Actually-Affected-by-the-Scrambling-of"
        target="_blank"
        rel="noreferrer"
      >
        <div className="project-link">
          <div className="title">
            Margalit, E., Biederman, I., Tjan, B. S., & Shah, M. P. (2017). What
            is actually affected by the scrambling of objects when localizing
            the lateral occipital complex?. Journal of cognitive neuroscience,
            29(9), 1595-1604.
          </div>
        </div>
      </a>
    </div>
  );
}

export default LOCFamiliarity;
